import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tour: Multi User Accounts`}</h1>
    <hr></hr>
    <h2>{`Multi Client / Multi User Access`}</h2>
    <p>{`With Dashbot organizations, you can create groupings of chatbots and skills and decide who has access to each.`}</p>
    <p>{`For example if you are working with multiple clients, create an organization for each one.`}</p>
    <p>{`Users can be assigned different roles depending on what functionality you want the user to have.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/m1gkFffaAyE" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Cross Client and Channel Comparisons`}</h2>
    <p>{`See how your chatbots, Alexa skills, and Google actions compare against each other based on key metrics like users, messages, engagement, and more.`}</p>
    <p>{`Compare client organizations amongst each other.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/V1ue3yMbuwo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      